<template>
  <div class="login-wrapper">
    <div class="login-wrapper__logo-wrapper">
      <img class="login-wrapper__logo" src="@/assets/img/auth-user/omybrain.svg" alt="logo">
    </div>
    <span class="login-wrapper__title">С возвращением!</span>
    <form class="login-wrapper__form" @submit.prevent="handleSubmit">
      <div class="login-wrapper__form-title">Войдите в свою учетную запись</div>
      <div class="login-wrapper__form-group">
        <label class="login-wrapper__form-label" :class="{'login-wrapper__form-label--action': form.email !== ''}">E-mail</label>
        <form-input style="margin-bottom: 50px"
                    v-model="form.email"
                    :validator="$v.form.email"
                    placeholder="E-mail"/>
      </div>
      <div class="login-wrapper__form-group">
        <label class="login-wrapper__form-label" :class="{'login-wrapper__form-label--action': form.password !== ''}">Пароль</label>
        <form-input v-model="form.password"
                    :validator="$v.form.password"
                    type="password"
                    placeholder="Пароль"/>
        <div class="login-wrapper__form-prompt">Забыли пароль?</div>
      </div>
      <button :disabled="loading" class="login-wrapper__form-btn">
        <span v-if="!loading">Войти</span>
        <i v-else class="el-icon-loading"></i>
      </button>
    </form>
  </div>

</template>

<script>
import FormInput from '@/components/FormInput'
import {authService} from '@/app/admin/auth/auth.service'

import {validationMixin} from "vuelidate";
import {required, minLength, maxLength, email} from 'vuelidate/lib/validators'
import {validate} from "../../../utils/validators";

export default {
  name: "Login",
  mixins: [validationMixin],
  components: {FormInput},
  data() {
    return {
      loading: false,
      form: {
        email: '',
        password: ''
      },
      errors: {
        email: null,
        password: null
      }
    }
  },
  methods: {
    handleSubmit: function () {

      if(validate(this.$v)) {
        this.loading = true

        authService.auth(this.form)
            .then(() => this.$router.push('/admin'))
            .finally(() => this.loading = false)
      }
    }
  },
  validations: {
    form: {
      email:{required,email:email},
      password: {required, minLength: minLength(6), maxLength: maxLength(64)},
    }
  }
}
</script>

<style lang="scss">

html, body {
  height: 100%;
}

.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  max-width: 570px;
  width: 100%;

  height: 100%;

  &__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 200px;
    height: 200px;
    background: #FFFFFF;
    border-radius: 100%;
    margin-bottom: 50px;
  }
  &__logo {
    width: 90%;
  }

  &__title {
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;

    color: #FFFFFF;

    margin-bottom: 33px;
  }

  &__form-title {
    font-family: Montserrat, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;

    color: #FFFFFF;

    margin-bottom: 120px;
  }

  &__form-label {
    position: absolute;

    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;

    color: #FFFFFF;

    top: 20px;
    margin-left: 15px;

    opacity: 0;

    &--action {
      top: -15px;
      opacity: 1;
      transition: 0.2s;
    }
  }

  &__form-prompt {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: right;

    color: #FFFFFF;
    cursor: pointer;
  }

  &__form {
    z-index: 1;
    .form-input {
      &__error-text {
        font-size: 14px;
        color: #FFFFFF;
      }

      &__input {
        font-family: Montserrat, sans-serif;
        font-size: 20px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: #FFFFFF !important;

        height: 35px;
        border: none;
        border-bottom: 1px solid #FFFFFF;
        border-radius: 0px;
        padding-right: 35px;
        padding-left: 14px;
        margin-top: 7px;
        font-size: 14px;
        color: #1B1B1B;
        font-weight: normal;
        transition: .2s;

        background: transparent;

        &::-webkit-input-placeholder {
          font-family: Montserrat, sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #FFFFFF;
        }

        &:focus {
          border-color: #FFFFFF;
        }
      }
    }
  }

  &__form-group {
    position: relative;
  }

  &__form-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none !important;

    width: 100%;
    max-width: 450px;
    height: 72px;

    margin: 0 auto;

    font-family: Montserrat, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;


    margin-top: 96px;
    background: #1454F2;
    border-radius: 50px;

    color: #FFFFFF;
    cursor: pointer;
  }

}

@media screen and (max-height: 800px) {
  html, body {
    height: max-content;
  }
  .login-wrapper {
    padding: 20px 0;

    &__form-title {
      margin-bottom: 60px;
      transition: 0.3s;
    }
  }
}
</style>
